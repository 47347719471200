<template>
  <div>
    <div class="Top_box">
      <span>控制权限申请</span>
    </div>
    <div class="top_box">
      <el-button
        :disabled="disabled"
        type="primary"
        class="edit"
        @click="dialogVisible = true"
        >控制权限申请<i class="el-icon-circle-plus-outline el-icon--right"></i
      ></el-button>
      <el-button
        style="
          margin-left: 10px;
          border-color: #5a8bff;
          background-color: #f3f3fb;
          color: #5a8bff;
        "
        type="primary"
        plain
        icon="el-icon-document-checked"
        @click="changeStatus(2)"
        >同意</el-button
      >
      <el-button
        style="
          margin-left: 10px;
          border-color: #5a8bff;
          background-color: #f3f3fb;
          color: #5a8bff;
        "
        type="primary"
        plain
        icon="el-icon-document-checked"
        @click="changeStatus(1)"
        >拒绝</el-button
      >
      <span style="margin-top: 3px; margin-left: 5px;" @click="toDelItem">
        <img
          style="cursor: pointer;width:30px;height:30px"
          src="../../assets/images/Examine_img/del.png"
        />
      </span>
    </div>

    <el-table
      :data="ApplyAccess.slice(start, end)"
      style="width: 100%"
      highlight-current-row
      @row-click="handleRow"
      :header-cell-style="{
        background: '#EDF0F7',
        color: '#000000',
      }"
    >
      <el-table-column prop="applyUserName" label="申请人" width="180">
      </el-table-column>
      <el-table-column prop="companyName" label="公司" width="180">
      </el-table-column>
      <el-table-column prop="deptName" label="部门"> </el-table-column>
      <el-table-column prop="days" label="申请天数"> </el-table-column>
      <el-table-column prop="saveTime" label="申请时间" :formatter="dateFormat"> </el-table-column>
      <el-table-column prop="address" label="审批结果">
        <template slot-scope="scope">
          <span v-show="scope.row.status === 0">未审批</span>
          <span v-show="scope.row.status === 2">通过</span>
          <span v-show="scope.row.status === 1">未通过</span>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="控制权限申请"
      :visible.sync="dialogVisible"
      width="23%"
      :before-close="handleClose"
    >
      <el-form>
        <el-form-item label="申请天数：">
          <el-select class="elselect" v-model="day" placeholder="请选择">
            <el-option
              v-for="item in list"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="postApply">确认</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提示 " :visible.sync="dialogVisibledel" width="20%">
      <span>确定删除?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibledel = false">取 消</el-button>
        <el-button type="primary" @click="delItem">确认</el-button>
      </span>
    </el-dialog>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage4"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="num"
      class="_Pagination"
    >
    </el-pagination>
  </div>
</template>

<script>
import { deleteMethod, get, post, put } from '../../api/http'
export default {
  data() {
    return {
      ApplyAccess: [],
      dialogVisible: false,
      dialogVisibledel: false,
      day: '',
      CurId: null,
      start: 0,
      end: 10,
      curSize: 10,
      list: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
    }
  },
  methods: {
    dateFormat(row){
      // 创建一个Date对象  
      const date = new Date(row.saveTime);  
      // 获取各个部分的值  
      const year = date.getFullYear();  
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以需要+1  
      const day = String(date.getDate()).padStart(2, '0');  
      const hours = String(date.getHours()).padStart(2, '0');  
      const minutes = String(date.getMinutes()).padStart(2, '0');  
      const seconds = String(date.getSeconds()).padStart(2, '0');  
      // 格式化输出  
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;  
    },
    getApplyAccess() {
      get('/api/ApplyAccess/GetList').then((res) => {
        this.ApplyAccess = res.data
        this.num = res.data.length
      })
    },
    delItem() {
      if (!this.CurId) return
      deleteMethod('/api/ApplyAccess?Id=' + this.CurId).then((res) => {
        console.log(res)
        this.$message({
          type: 'success',
          message: res.message,
        })
        this.dialogVisibledel = false
        this.getApplyAccess()
      })
    },
    toDelItem() {
      this.dialogVisibledel = true
    },
    handleRow(e) {
      this.CurId = e.id
    },
    postApply() {
      const item = {
        compId: JSON.parse(sessionStorage.getItem('userInfo')).upCompanyId,
        companyName: '',
        deptId: JSON.parse(sessionStorage.getItem('userInfo')).upDeptmentId,
        deptName: '',
        applyUserId: JSON.parse(sessionStorage.getItem('userInfo')).id,
        applyUserName: JSON.parse(sessionStorage.getItem('userInfo')).userName,
        status: 0,
        days: this.day,
        agreeUserId: 0,
        agreeUserName: '',
      }
      post('/api/ApplyAccess', item).then((res) => {
        this.dialogVisible = false
        this.getApplyAccess()
        this.$message({
          type: 'success',
          message: res.message,
        })
      })
    },
    changeStatus(num) {
      put('/api/ApplyAccess?Id=' + this.CurId + '&Status=' + num).then(
        (res) => {
          this.$message({
            type: 'success',
            message: res.message,
          })
          this.getApplyAccess()
        }
      )
    },
    handleSizeChange(e) {
      this.curSize = e
      this.start = (this.currentPage1 - 1) * this.curSize
      this.end = this.start + this.curSize
    },
    handleCurrentChange(e) {
      this.start = (e - 1) * this.curSize
      this.end = this.start + this.curSize
    },
  },
  mounted() {
    this.getApplyAccess()
  },
}
</script>
<style>
.el-table__body tr.current-row > td {
  background: #d4e5f9 !important;
}
</style>
<style lang="less" scoped>
.Top_box {
  width: 100%;
  height: 55px;
  background: #4274c0;
  display: flex;
  align-items: center;
  span {
    width: 104px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    margin-left: 26px;
  }
}
.top_box {
  display: flex;
  align-items: center;
  background: #ffffff;
  height: 60px;
  padding-left: 20px;
}
</style>
